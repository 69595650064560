<template>
    <div class="sample_list_box">
        <!-- 操作按钮 -->
        <div class="sample_list_handle"
            v-if="order_status == 9">
            <slot name="download"></slot>
        </div>
        <div class="sample_list_handle" v-else>
            <!-- 
                上传、
                选中、
                留言 
            -->
            <slot name="submitData"></slot>
            <!-- 当前状态 -->
            <div class="sample_list_handle_status">
                <slot name="status"></slot>
            </div>
        </div>
        <!-- 备注 -->
        <slot name="tips" v-if="order_status != 9"></slot>
        <!-- 相册组 -->
        <el-row :gutter="20">
            <el-col :span="6"
				v-for="(item,index) in (verdict&&activePic.length>0?photographArray:sample_list)"
                :key="index">
                <a href="javascript:;"
                    :class="['sample_list_base',{
                        'sample_list_select': activePic.includes(item.id),
                    }]">
					<!-- 样片 -->
                    <el-card class="el-card">
						<div class="pictureFormat">
							<div class="picture">
								<!-- 图片容器 -->
								<a class="sample_album_img"
								    href="javascript:;"
								    @click="handleOpenDialog(item.spath)">
								    <img :src="item.spath" class="image" alt="">
								</a>
								<!-- 文字容器 -->
								<div class="sample_album_synopsis_box">
								    <!-- 相册介绍 -->
								    <div class="sample_album_synopsis">
								        <!-- 标题 -->
								        <span>{{item.name}}</span>
								    </div>
								    <!-- 创建时间及操作 -->
								    <div class="sample_album_create_time">
								        <!-- 时间 -->
								        <span>{{item.uptime}}</span>
								        <!-- 操作按钮 -->
								        <span class="sample_album_do">
								            <!-- 
								                选中、
								                取消选中、
								                留言、
								                显示留言
								             -->
								           <slot name="handleItem"
												v-if="verdict!=6&&verdict!=4&&verdict!=7&&verdict!=99"
								                :id="item.id"
								                :remarks="item.remarks"
								                :uptime="item.uptime"
								                :used="item.used">
								            </slot>
								        </span>
								    </div>
								</div>		
							</div>
									<!-- 上传精片 -->
							<div class="picture" v-if="verdict==6||verdict==4||verdict==7||verdict==99">
									<!-- 图片容器 -->
								<a class="sample_album_img"
									href="javascript:;"
									@click="handleOpenDialog(item.path)">
									<img v-if="item.path" :src="item.path" class="image" alt="">
									<img v-else src="https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1126878330,2942641081&fm=26&gp=0.jpg" class="image" alt="">
								</a>
								<!-- 文字容器 -->
								<div class="sample_album_synopsis_box">
								    <!-- 相册介绍 -->
								    <div class="sample_album_synopsis">
								        <!-- 标题 -->
								        <span>{{item.name}}</span>
								    </div>
								    <!-- 创建时间及操作 -->
								    <div class="sample_album_create_time">
								        <!-- 时间 -->
								        <span>{{item.uptime}}</span>
								        <!-- 操作按钮 -->
								        <span class="sample_album_do">
								            <div> 
												<slot name="handleItem"
												    :id="item.id"
												    :remarks="item.remarks"
												    :uptime="item.uptime"
												    :used="item.used">
												</slot>
								            </div>
								        </span>
								    </div>
								</div>
							</div>
						</div>
                    </el-card>
                    <!-- 修改状态显示 -->
                    <span class="sample_list_status" 
                        v-if="activeName == 1">
                        <span class="sample_list_status_true" v-if="item.status == 1">已修改</span>
                        <span class="sample_list_status_false" v-else>未修改</span>
                    </span>
                </a>
            </el-col>
        </el-row>
        <div v-if="order_status != 9">
            <!-- 
                完成样片上传、
                完成样片挑选、
                完成精片上传、
                完成留言
             -->
            <slot name="finish"></slot>
        </div>
        <img-dialog></img-dialog>
    </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
    computed: mapState({
        sample_list: state => state.order.sample_list,
        sample_status: state => state.order.sample_status,
        order_status: state => state.order.order_status,
        dialogVisible: state => state.dialogVisible,
    }),
	data() {
	    return {
	      photographArray:[]
	    }
	},
	watch:{
		sample_list(){
			this.photographArray = this.sample_list;
		}
	},
    props: [ "activePic", "activeName", "verdict",],
	mounted() {
		//隐藏未选中图片 
		 if(this.activePic.length>0){
				let arr = this.sample_list;
				let omtcer = arr.filter(item => item.used == 1)
				this.photographArray = omtcer;	
		}
		// console.log("选中照片",this.photographArray,"真实照片",this.sample_list)
		
	},
    components: {
        imgDialog: () => import('@/components/imgDialog'),
    },
	
    methods: {
        ...mapMutations([
            "changeDialogVisible",
            "writeDialogImgUrl"
        ]),
		//查看大图
        handleOpenDialog(arg){
            this.writeDialogImgUrl(arg);
            this.changeDialogVisible(true);
        }
    }
}
</script>

<style scoped>
.sample_list_box>.el-row>.el-col{
    margin-bottom: 10px;
	width:50%;
}
.sample_list_handle{
    margin-bottom: 10px;
}
/* 当前状态 */
.sample_list_handle_status{
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
    color: #F56C6C;
}
/* 样片边框 */
.sample_list_base{
    position: relative;
    display: block;
    border: 2px solid transparent;
	/* height: 500px; */
}
/* 选中样片 */
.sample_list_active{
    border-color: #409EFF;
}
/* 挑选样片 */
.sample_list_select{
    border-color: #E6A23C;
}
/* 图片容器 */
.sample_album_img{
	display: block;
    position: relative;
	width: 100%;
    overflow: hidden;
    margin-bottom: 10px;
}
.sample_album_img::before{
    display: block;
    content: "";
    padding-top: 100%
}
.sample_album_img>img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-width: 100%;
}
/* 相片名称容器 */
/* .sample_album_synopsis{
    width: 100%;
    padding: 0 10px;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
} */
/* 精片上传样 */
.sample_album_synopsis{
    width: 200px;
    padding: 0 10px;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
/* 相册操作按钮 */
.sample_album_create_time>span{
    display: inline-block;
    vertical-align: middle;
    color: #909399
}
.sample_album_create_time>.sample_album_do{
    float: right;
}

/* 精片修改状态 */
.sample_list_status{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10%;
    opacity: 1;
    text-align: center;
    color: #FFF;
    font-size: 18px;
    font-weight: bold;
    background-color: rgba(0,0,0,.6);
}
.sample_list_status_true{
    color: #E6A23C;
}
.sample_list_status_false{
    color: #fff;
}
.pictureFormat{
	display:flex;
}
.picture{
	flex:1;
	padding: 10px;
}
</style>
